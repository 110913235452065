.footer {
    padding: 10px 0;
    /* background-color: #f8f9fa; */
}

.footer-content {
    max-width: 1200px;
    margin: 0 auto;
    text-align: center;
    font-size: 0.85rem;
    font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace;
    font-weight: 300;
}

.stats-row {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;
    margin-bottom: 10px;
}

.stat-box {
    padding: 5px 10px;
    border-radius: 5px;
    color: white;
    font-weight: bold;
    white-space: nowrap;
}

.stat-views { background: #E57373; }
.stat-visitors { background: #FFB74D; }
.stat-ips { background: #64B5F6; }
.runtime-box { 
    background: #81C784;
    display: inline-block;
}
.copyright-row {
    margin-top: 10px;
    font-size: 1rem;
    color: #666;
    font-weight: bold;
    font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace;
}

.copyright-row a {
    color: #0066cc;
    text-decoration: none;
}

.copyright-row a:hover {
    text-decoration: underline;
}

@media (max-width: 768px) {
    .stats-row {
        flex-wrap: wrap;
    }
    
    .stat-box {
        font-size: 0.75rem;
        padding: 3px 6px;
    }

    .runtime-box {
        width: auto;
        margin-top: 5px;
        justify-content: center;
    }

    .copyright-row {
        font-size: 0.75rem;
        font-weight: bold;
        font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace;
    }
}
